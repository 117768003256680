#titulotabelapessoas {
    margin-top: 22px;
}

.validationerror {
    color: red;
}

.titulocampo {
    margin-top: 50px;
}

.subtitulo {
    margin-bottom: 5px;
}

.margemdivider {
    margin-bottom: 10px;
    margin-top: -7px;
}

.acertasubtitulo {
    margin-left: -15px;
}

.botaoparams {
    margin-left: -50px;
    margin-top: -1px;
}

.botaoparams2 {
    margin-left: -25px;
    margin-top: -1px;
}

.botaonav {
    width: 200px;
}

.posicaorb {
    margin-left: 20px;
}

.acertaposicaorb {
    margin-top: -15px;
}

.espacamentorb {
    margin-right: 20px;
}

.acertaposicaolabel {
    margin-top: -7px;
}

.posicaotextfield {
    margin-left: 60px;
}

.posicaotextfield40 {
    margin-left: 40px;
}

.circulopreto {
    padding: 3px;
    color: white;
    background-color: gray;
    border-radius: 10px;
}

.bordadireita {
    border-right-width: 1px;
    border-right-color: lightgray;
    border-right-style: solid;
}

.bordaesquerda {
    border-left-width: 1px;
    border-left-color: lightgray;
    border-left-style: solid;
}

.bordasuperior {
    border-top-width: 1px;
    border-top-color: lightgray;
    border-top-style: solid;
}

.bordainferior {
    border-bottom-width: 1px;
    border-bottom-color: lightgray;
    border-bottom-style: solid;
}

.bordacompleta {
    border-width: 1px;
    border-color: lightgray;
    border-style: solid;
}

.linhatabela {
    height: 75px;
}

.linhatabela2 {
    height: 48px;
}

.linhatabela2ajustatextfield {
    padding-top: 4px;
}

.linhatabela2ajustatexto {
    padding-top: 35px;
}

.linhatabela2ajustatotal {
    padding-top: 15px;
}

.linhatabela2ajustahomem {
    padding-top: 13px;
}

.linhatabela2ajustamulher {
    padding-top: 23px;
}

.ajustadadosgps {
    padding-top: 120px;
}

.textodiferenca {
    color: red;
    font-size: larger;
}

.titulomodal {
    color: red;
    font-size: large;
}

.seletor {
    width: 200px;
}

.direita {
    float: right;
}

.inputLabelConfig {
    background-color: white;
    margin-left: -5px;
}

.botaonovo {
    width: auto;
    height: auto;
    font-size: x-small;
}

.cabectab {
    font-size: larger;
    background-color: #535353;
    font-weight: bolder;
    color: white;
}
