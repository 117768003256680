.tituloselecao {
    background-color: lightgray;
    padding-top: 5px;
    padding-bottom: 1px;
}

.selecao {
    background-color: white;
    padding-top: 20px;
    padding-bottom: 20px;
}