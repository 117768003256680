.app {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  text-align: center;
  position: absolute;
  width: 100%;
  height: 100%;
  background-image: url("../../images/agro2.jpg");
  background-size: cover;
  color: black;
}

.mensagem {
  color: black;
}

.form-signin {
  width: 100%;
  max-width: 350px;
  margin: auto;
  background-color: white;
  padding: 30px;
}

.inputs {
  width: 90%;
  margin: 0px;
}

.btn {
  width: 90%;
}

.borda {
  -moz-border-radius:8px;
  -webkit-border-radius:8px; 
  -o-border-radius:8px; 
  -ms-border-radius:8px; 
  border-radius:8px;
}

.titulo {
  background-color: darkslategray;
  color: white;
  margin-top: -50px;
  padding-bottom: 7px;
}

img {
  width: 100px;
  height: 100px;
}

.versao {
  margin-top: 3px;
  margin-bottom: -25px;
  font-size: smaller;
  font-weight: bold;
  color: lightgray;
}