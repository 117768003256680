html {
    background-color: white;
}

.menu {
    background-color: white;
}

.cards {
    width: 275px;
    height: 200px;
}

.dashboard {
  height: 300px;
  text-align: center;
  align-items: center;
  display: flex;
  justify-content: center;
}

.bordadireita {
    border-right-width: 1px;
    border-right-color: lightgray;
    border-right-style: solid;
  }
  
  .bordaesquerda {
    border-left-width: 1px;
    border-left-color: lightgray;
    border-left-style: solid;
  }
  
  .bordasuperior {
    border-top-width: 1px;
    border-top-color: lightgray;
    border-top-style: solid;
  }
  
  .bordainferior {
    border-bottom-width: 1px;
    border-bottom-color: lightgray;
    border-bottom-style: solid;
  }
  
  .bordacompleta {
    border-width: 1px;
    border-color: lightgray;
    border-style: solid;
  }