html {
    background-color: white;
}

.geral {
    background-color: white;
    color: black;
}
  
.tabela {
    width: 96%;
    margin: 0px;
    text-align: left;
}

.camposbusca {
    width: 100%;
}

.botoes {
    width: auto;
}

.quadrobusca {
    margin-top: 10px;
    margin-bottom: 10px;
}

.colkey {
    text-align: center;
    width: 5%;
}
.colbuttons {
    text-align: right;
    width: 10%;
}
  
img {
    width: 20px;
    height: 20px;
}

.modal {
    color: black;
}

.botaopesquisa {
    margin-top: 10px;
}

.botaonovo {
    margin-left: -10px;
    width: auto;
}

.cabeccadastro {
    color: darkblue;
    font-weight: bolder;
    text-align: left;
    margin-left: -30px;
}

